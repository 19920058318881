hr {
    width: 100%;
}

#error-box {
    display: none;
    position: fixed;
    bottom: 20px; 
    right: 20px;
    width: 320px;
    padding: 10px;
    background-color: #b0002055; 
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    z-index: 1000;
}
